
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class DateTimeEditor extends Vue {
  @Prop() value!: Array<string>;
  @Prop() pickerAttrs!: any;
  @Prop() inputAttrs!: any;
  menuVisible: boolean = false;
  get formatValue() {
    return this.value.length == 2
      ? `C ${this.value[0]} по ${this.value[1]}`
      : "";
  }
  get calendarValue() {
    return this.value;
  }
  set calendarValue(val: Array<string>) {
    this.$emit("input", val);
  }
  update(val: any) {
    this.$emit("input", val);
    this.menuVisible = false;
  }
}
